<template>
	<div class="content">

		<div class="">

			<div class="header text-center item-title" style="padding:10px;font-size: 16px;font-weight: 600"
				contenteditable @keyup.ctrl.enter="edit('name',detail.id)" :id="'name'+detail.id">{{detail.name}}</div>

			<el-card class="">
				<div v-for="question in detail.questions" :key="question.index" class="text item question">
					<div class="question-type" v-if="question.type === 1">第{{question.order}}题【 单选题 】</div>
					<div class="question-type" v-else-if="question.type === 2">第{{question.order}}题【 多选题 】</div>
					<div class="question-type" v-else-if="question.type === 3">第{{question.order}}题【 判断题 】</div>
					<div class="question-type" v-else-if="question.type === 4">第{{question.order}}题【 填空题 】</div>
					<div class="question-type" v-else-if="question.type === 5">第{{question.order}}题【 思维导图 】</div>
					<div class="question-title" v-if="question.type != 5">
						<span>{{question.order + '.'}}</span>
						<span class="text item-title" contenteditable @keyup.ctrl.enter="edit('question',question.id)"
							:id="'question'+question.id">
							<p style="display: inline-block;" v-html="question.content"></p>
						</span>
					</div>
					<div v-else>
						<mindmap height="400" v-model="question.content"></mindmap>
					</div>
					<div v-for="(items,index) in detail.items" :key="index" class="text item ">
						<div v-if="question.text_type === 1 && question.type !== 3 && question.type !== 4">
							<div v-if="items.questions_id === question.id" class="margin-l-sm question-item"
								style="line-height: 2rem;font-size: 0.8rem;">
								<div v-if="items.sort === 1" type="info">{{sort.A + '.'}}</div>
								<div v-else-if="items.sort === 2" type="info">{{sort.B + '.'}}</div>
								<div v-else-if="items.sort === 3" type="info">{{sort.C + '.'}}</div>
								<div v-else-if="items.sort === 4" type="info">{{sort.D + '.'}}</div>
								<div v-else-if="items.sort === 5" type="info">{{sort.E + '.'}}</div>
								<div v-else-if="items.sort === 6" type="info">{{sort.F + '.'}}</div>
								<span contenteditable @keyup.ctrl.enter="edit('item',items.id)" :id="'item'+items.id">
									<p style="display: inline-block;" v-html="items.item"></p>
								</span>
							</div>
						</div>
						<div v-else-if="question.text_type === 2 && question.text_type !== 3 && question.type !== 4"
							class="">
							<span v-if="items.questions_id === question.id" class="margin-l-sm question-item"
								style="vertical-align: middle;">
								<div>
									<div v-if="items.sort === 1" type="info">{{sort.A + '.'}}</div>
									<div v-else-if="items.sort === 2" type="info">{{sort.B + '.'}}</div>
									<div v-else-if="items.sort === 3" type="info">{{sort.C + '.'}}</div>
									<div v-else-if="items.sort === 4" type="info">{{sort.D + '.'}}</div>
									<div v-else-if="items.sort === 5" type="info">{{sort.E + '.'}}</div>
									<div v-else-if="items.sort === 6" type="info">{{sort.F + '.'}}</div>
								</div>
								<!-- <img width="100" height="100" :src="path+items.item"> -->
								<el-image style="width: 100px; height: 100px; vertical-align: middle;"
									:src="path+items.item" fit="contain" lazy>
									<div slot="error" class="image-slot">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</span>
						</div>
						<div v-else>
							<span v-if="items.questions_id === question.id" class="margin-l-sm"
								style="line-height: 2rem;font-size: 0.8rem;">
								<p style="display: inline-block;" v-html="items.item"></p>
							</span>
						</div>
					</div>
					<div v-for="answer in detail.answer" :key="answer.index" class="text item ">
						<div class="question-answer" v-if="answer.questions_id === question.id">
							<el-tag type="success">正确答案: </el-tag>
							<p v-if="question.text_type === 1 && question.type != 5">
								<span class="margin-l-sm" v-for="(item,index) in answer.answer" :key="index"
									contenteditable @keyup.ctrl.enter="edit('answer',answer.id)"
									:id="'answer'+answer.id">
									<p style="display: inline-block;" v-html="item"></p>
								</span>
							</p>
							<div v-else>
								<div v-for="i in answer.answer" :key="i.id">
									<span>{{i.id}}的答案: 
										<span style="width: 7px;height: 100%;display: inline-block;"></span> </span>
									<span v-for="ci in i.items" :key="ci.key">{{ci.vla}}</span>
								</div>
							</div>
							<p v-if="question.text_type === 2 && question.type != 5">
								<span class="margin-l-sm" v-for="(item,index) in answer.answer" :key="index">
									<el-image style="width: 80px; height: 80px;padding:10px" :src="imageUrl + item"
										:preview-src-list="[imageUrl + item]">
									</el-image>
								</span>
							</p>
						</div>
					</div>
					<div v-for="analyses in detail.analyses" :key="analyses.index" class="text item">
						<div v-if="analyses.questions_id === question.id">
							<el-tag type="warning">本题解析: </el-tag>
							<span class="margin-l-sm" contenteditable
								@keyup.ctrl.enter.down="edit('analyse',analyses.analyses.id)"
								:id="'analyse'+analyses.analyses.id">
								<p style="display: inline-block;" v-html="analyses.analyses.content"></p>
							</span>
						</div>
					</div>
				</div>
			</el-card>
		</div>
	</div>
</template>
<script>
import mindmap from '@hellowuxin/mindmap'
import { mapActions } from 'vuex'
import { config } from '../../../js/config'
export default {
	name: "detail",
	components: { mindmap },
	data() {
		return {
			imageUrl: config.imageUrl,
			id: 0,
			detail: [],
			path: config.imageUrl,
			sort: {
				A: 'A',
				B: 'B',
				C: 'C',
				D: 'D',
				E: 'E',
				F: 'F'
			}
		}
	},
	created() {
		this.id = this.$route.query.id
		this.getDetail()
	},
	methods: {
		...mapActions('questionBank', ['getQuestionBankDetail', 'updateQuestionBankName', 'updateContent']),
		async getDetail() {
			var vm = this
			const { res_info, data } = await this.getQuestionBankDetail({ id: this.id })
			if (res_info !== 'ok') return
			data?.questions.forEach((v, i) => {
				if (v.type == 5) {
					data.questions[i].content = JSON.parse(data.questions[i].content)
				}
			})
			vm.detail = data
		},
		edit(name, id) {
			var elemetId = '#' + name + id
			var value = $(elemetId).text()
			switch (name) {
				// 改题库名称
				case 'name':
					if (value !== this.detail.name) {
						this.updateQuestionBankName({ id: id, name: value })
					}
					break
				// 改题目
				case 'question':
					this.updateContent({ model: 'questions', id: id, content: value })
					break
				// 修改选项 只支持非图片
				case 'item':
					this.updateContent({ model: 'questionsItems', id: id, content: value })
					break
				// 修改答案 只支持非图片
				case 'answer':
					this.updateContent({ model: 'answers', id: id, content: value })
					break
				// 修改解析
				case 'analyse':
					this.updateContent({ model: 'analyses', id: id, content: value })
					break
				default:
					return
			}
			this.$message.success('已保存')
		}
	}
}
</script>

<style>
* {
	outline: none;
}

.question {
	padding: 10px;
	border: 1px solid #eee;
	margin-bottom: 10px;
}

.question-type {
	padding-bottom: 10px;
}

.question-title {
	display: flex;
	align-items: center;
	padding: 10px 0;
}

.question-item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.question-answer {
	display: flex;
	align-items: center;
	margin: 10px 0;
}
</style>